/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { useContext } from 'react';

import { jsx } from '@compiled/react';

import { cssMap } from '@atlaskit/css';
import { fg } from '@atlaskit/platform-feature-flags';
import { token } from '@atlaskit/tokens';

import { HomeActionsAttachRef } from '../../context/home-actions/home-actions-context';

const styles = cssMap({
	root: {
		// Taking up the full height of top bar to allow for monitoring mouse events, for improving the side nav flyout experience
		height: '100%',
		alignItems: 'center',
		display: 'flex',
		gap: token('space.050'),
		gridColumn: 1,
	},
	// Adds extra padding between the logo and search bar when they are pressed against each other
	logoPaddingStyles: {
		'@media (min-width: 30rem)': {
			paddingInlineEnd: token('space.075'),
		},
	},
	// Sets a minimum width on the content so that it aligns with the side nav (when side nav is at default width)
	contentMinWidthStyles: {
		'@media (min-width: 64rem)': {
			// Intrinsic width of content without wrapping
			// The actual grid column can still be larger
			width: 'max-content',
			/**
			 * Using `300px` because:
			 *
			 * - Side nav default width is `320px`
			 * - Top bar has `12px` padding on each side
			 * - There is an `8px` gap between the home actions and common actions
			 *
			 * So `320px` - (`12px` + `8px`) = `300px`
			 */
			minWidth: '300px',
			// We want the specified width to be inclusive of padding
			boxSizing: 'border-box',
		},
	},
});

/**
 * __Home actions__
 *
 * Wrapper for the top navigation actions on the inline-start (left) side of the top navigation.
 */
export const HomeActions = ({
	children,
	testId,
}: {
	children: React.ReactNode;
	testId?: string;
}) => {
	const ref = useContext(HomeActionsAttachRef);

	return (
		<div
			css={[
				styles.root,
				fg('platform_design_system_nav4_top_nav_min_widths')
					? styles.contentMinWidthStyles
					: // Removing the extra logo padding when applying the content min width
						styles.logoPaddingStyles,
			]}
			ref={ref}
			data-testid={testId}
		>
			{children}
		</div>
	);
};
