/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import React, { useEffect, useRef } from 'react';

import { cssMap, jsx } from '@compiled/react';

import type { LogoProps } from '@atlaskit/logo';
import { fg } from '@atlaskit/platform-feature-flags';
import { Anchor } from '@atlaskit/primitives';
import type {
	IconProps as TempIconProps,
	LogoProps as TempLogoProps,
} from '@atlaskit/temp-nav-app-icons/types';
import { token } from '@atlaskit/tokens';

import { useHasCustomTheme } from './themed/has-custom-theme-context';

export const themedLogoIcon = '--ds-top-bar-logo-icon';
export const themedLogoText = '--ds-top-bar-logo-text';

const navLogoStyles = cssMap({
	root: {
		display: 'flex',
		alignItems: 'center',
		height: '32px',
		borderRadius: token('border.radius.100'),
	},
	icon: {
		display: 'flex',
		// Max width the icon can display at (a square)
		maxWidth: 24,
		// Using `content-box` sizing because the padding is irrelevant for the maxWidth
		boxSizing: 'content-box',
		paddingInline: token('space.050'),
		'@media (min-width: 64rem)': {
			// '&&' is required to add more CSS specificity to resolve non-deterministic ordering, which can result in
			// both the `icon` and `logo` elements to be displayed at the same time
			// Clean up task: https://jplat.atlassian.net/browse/BLU-4788
			// @ts-ignore
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
			'&&': {
				display: 'none',
			},
		},
	},
	logo: {
		display: 'none',
		// Max width the logo can display at
		maxWidth: 320,
		// Using `content-box` sizing because the padding is irrelevant for the maxWidth
		boxSizing: 'content-box',
		paddingInline: token('space.100'),
		'@media (min-width: 64rem)': {
			// '&&' is required to add more CSS specificity to resolve non-deterministic ordering, which can result in
			// both the `icon` and `logo` elements to be displayed at the same time
			// Clean up task: https://jplat.atlassian.net/browse/BLU-4788
			// @ts-ignore
			// eslint-disable-next-line @atlaskit/design-system/no-nested-styles, @atlaskit/ui-styling-standard/no-nested-selectors, @atlaskit/ui-styling-standard/no-unsafe-selectors
			'&&': {
				display: 'flex',
			},
		},
	},
	imageContainer: {
		// Overflow should never occur, but hiding it just in case
		overflow: 'hidden',
		/**
		 * Using a nested selector because we cannot resize the image correctly by only styling the container.
		 *
		 * We could have changed the API to accept a string, and we would render the image ourselves.
		 * But there is no way to prevent makers from rendering the image themselves (even if by accident).
		 *
		 * This seemed like the more robust option.
		 */
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors
		img: {
			// Using a fixed height and letting the width auto-adjust.
			height: 24,
			maxWidth: 'inherit',
			// The replaced content is scaled to maintain its aspect ratio while fitting within the element's content box.
			// So if the replaced content is too wide, it can display at a smaller height than the element's fixed height.
			objectFit: 'contain',
		},
	},
	iconLogoPaddingFlagged: {
		paddingInline: token('space.050'),
	},
});

/**
 * __Nav logo__
 *
 * The product logo for the top navigation.
 */
export const NavLogo = ({
	href,
	logo,
	icon,
	onClick,
	label,
}: {
	label: string;
	href: string;
	logo: ((props: LogoProps) => JSX.Element) | ((props: TempLogoProps) => JSX.Element);
	icon: ((props: LogoProps) => JSX.Element) | ((props: TempIconProps) => JSX.Element);
	onClick?: React.MouseEventHandler<HTMLAnchorElement>;
}) => {
	const ref = useRef<HTMLAnchorElement>(null);

	useEffect(() => {
		if (process.env.NODE_ENV === 'production') {
			return;
		}

		if (!ref.current) {
			return;
		}

		const img = ref.current.querySelector('img');
		if (!img) {
			return;
		}

		/**
		 * We want custom logo components to render the image as the immediate child
		 */
		const hasExpectedStructure = img.parentElement?.parentElement === ref.current;
		/**
		 * We want custom logo components to render only one image
		 */
		const isOnlyChild = img.parentElement?.childElementCount === 1;

		if (!hasExpectedStructure || !isOnlyChild) {
			// eslint-disable-next-line no-console
			console.error('Custom logos should render only a single image tag with no wrappers.');
		}

		if (
			img.getAttribute('width') !== null ||
			img.getAttribute('height') !== null ||
			img.style.width !== '' ||
			img.style.height !== ''
		) {
			// eslint-disable-next-line no-console
			console.error(
				'Do not set explicit dimensions on custom logo images. The container will resize the image to fit.',
			);
		}
	}, []);

	return (
		<Anchor ref={ref} aria-label={label} href={href} xcss={navLogoStyles.root} onClick={onClick}>
			<div
				css={[
					navLogoStyles.imageContainer,
					navLogoStyles.icon,
					fg('platform-team25-app-icon-tiles') && navLogoStyles.iconLogoPaddingFlagged,
				]}
			>
				<NavLogoRenderer logoOrIcon={icon} />
			</div>
			<div
				css={[
					navLogoStyles.imageContainer,
					navLogoStyles.logo,
					fg('platform-team25-app-icon-tiles') && navLogoStyles.iconLogoPaddingFlagged,
				]}
			>
				<NavLogoRenderer logoOrIcon={logo} />
			</div>
		</Anchor>
	);
};

function NavLogoRenderer({
	logoOrIcon: LogoOrIcon,
}: {
	logoOrIcon:
		| ((props: LogoProps) => JSX.Element)
		| ((props: TempLogoProps) => JSX.Element)
		| ((props: TempIconProps) => JSX.Element);
}) {
	const hasCustomTheme = useHasCustomTheme();

	if (hasCustomTheme) {
		return (
			<LogoOrIcon
				size="small"
				label=""
				iconColor={`var(${themedLogoIcon})`}
				textColor={`var(${themedLogoText})`}
			/>
		);
	}

	return <LogoOrIcon size="small" label="" appearance="brand" />;
}
