/**
 * @jsxRuntime classic
 * @jsx jsx
 */
import { useContext } from 'react';

import { cssMap, jsx } from '@compiled/react';

import type { StrictXCSSProp } from '@atlaskit/css';
import { fg } from '@atlaskit/platform-feature-flags';

import { useSkipLinkInternal } from '../../context/skip-links/skip-links-context';

import { bannerMountedVar, localSlotLayers, UNSAFE_bannerVar } from './constants';
import { DangerouslyHoistSlotSizes } from './hoist-slot-sizes-context';
import { DangerouslyHoistCssVarToDocumentRoot, HoistCssVarToLocalGrid } from './hoist-utils';
import type { CommonSlotProps } from './types';
import { usePrefixedUID } from './use-prefixed-id';

const styles = cssMap({
	root: {
		gridArea: 'banner',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values
		height: `var(${bannerMountedVar})`,
		insetBlockStart: 0,
		position: 'sticky',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		zIndex: localSlotLayers.banner,
		overflow: 'hidden',
	},
});

export function Banner({
	children,
	xcss,
	UNSAFE_height = 64,
	skipLinkLabel = 'Banner',
	testId,
	id,
}: CommonSlotProps & {
	xcss?: StrictXCSSProp<'backgroundColor', never>;
	/**
	 * Not intended for long term use. This is added to support the migration to the new page layout.
	 * We intend to replace this with a banner height prop on `<Root>`.
	 */
	UNSAFE_height?: number;
}) {
	const dangerouslyHoistSlotSizes = useContext(DangerouslyHoistSlotSizes);
	const UID = usePrefixedUID();
	const CID: string = id ? id : UID;

	/**
	 * Don't show the skip link if the slot has 0 height.
	 */
	useSkipLinkInternal({
		id: CID,
		label: skipLinkLabel,
		isHidden: UNSAFE_height === 0 && fg('platform_design_system_nav4_hide_empty_skip_links'),
	});

	return (
		/**
		 * Intentionally not using `role="banner"` because each page should only have one `banner` landmark,
		 * and the top bar is more suitable as the `banner` landmark.
		 */
		<div id={CID} data-layout-slot css={styles.root} className={xcss} data-testid={testId}>
			<HoistCssVarToLocalGrid variableName={bannerMountedVar} value={UNSAFE_height} />
			{dangerouslyHoistSlotSizes && (
				// ------ START UNSAFE STYLES ------
				// These styles are only needed for the UNSAFE legacy use case for Jira + Confluence.
				// When they aren't needed anymore we can delete them wholesale.
				<DangerouslyHoistCssVarToDocumentRoot
					variableName={UNSAFE_bannerVar}
					value={UNSAFE_height}
				/>
				// ------ END UNSAFE STYLES ------
			)}
			{children}
		</div>
	);
}
